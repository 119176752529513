import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH, IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_DEFAULT_IDENTITY, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY } from "@/presentation/common-utils/constants";
import { LinkedList, ListNode } from "@/presentation/common-utils/data-structures";
export const authenticationOptions = [
    {
        label: "Basic Authentication",
        // description:
        //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests. Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where all resources in the account are governed by the same security controls.",
        value: IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH,
    },
    {
        label: "CIDR Ranges",
        // description:
        //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests." +
        //   " Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where" +
        //   " all resources in the account are governed by the same security controls.",
        value: IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE,
    },
    {
        label: "Cloud Tenants",
        // description:
        //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests." +
        //   " Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where" +
        //   " all resources in the account are governed by the same security controls.",
        value: IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT,
    },
    {
        label: "User Identity",
        // description:
        //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests." +
        //   " Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where" +
        //   " all resources in the account are governed by the same security controls.",
        value: IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY,
    },
    {
        label: "Default Identity",
        value: IDENTITY_AUTHENTICATION_TYPE_DEFAULT_IDENTITY,
    },
    // {
    //   label: "Client Certificates",
    //   // description:
    //   //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests." +
    //   //   " Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where" +
    //   //   " all resources in the account are governed by the same security controls.",
    //   value: "item3",
    //   disabled: true,
    // },
    // {
    //   label: "OIDC Provider",
    //   // description:
    //   //   "A cloud tenant such as an AWS Account, GCP Project or Azure Subscription can be used to authenticate requests." +
    //   //   " Requests originating from a cloud tenant can then have policies applied to them. This is useful for per-application accounts where" +
    //   //   " all resources in the account are governed by the same security controls.",
    //   value: "item4",
    //   disabled: true,
    // },
];
export const getIdentifierLabel = () => {
    const identityObject = {};
    authenticationOptions.forEach(item => {
        const { label, value } = item;
        identityObject[value] = label;
    });
    return identityObject;
};
export const getPathsOfTenantStructure = (tenantStructure) => {
    let path = {};
    // first pass fill up the object
    Object.keys(tenantStructure).forEach(key => {
        path[key] = '';
    });
    // second pass fill up the nested objects
    Object.keys(tenantStructure).forEach(key => {
        var _a, _b, _c;
        if ((_a = tenantStructure[key]) === null || _a === void 0 ? void 0 : _a.Nested) {
            (_c = (_b = tenantStructure[key]) === null || _b === void 0 ? void 0 : _b.Nested) === null || _c === void 0 ? void 0 : _c.forEach(id => {
                var _a, _b;
                const list = new LinkedList();
                const parentNode = new ListNode({
                    key: key,
                    name: (_a = tenantStructure[key]) === null || _a === void 0 ? void 0 : _a.Name
                });
                if (!path[id]) {
                    const currentNode = new ListNode({
                        key: id,
                        name: (_b = tenantStructure[id]) === null || _b === void 0 ? void 0 : _b.Name
                    });
                    list.appendNode(currentNode);
                    if (path[key]) {
                        const parentList = path[key];
                        list.appendNode(parentList.getNode(0));
                    }
                    else {
                        list.appendNode(parentNode);
                    }
                    path[id] = list;
                }
                else {
                    // this is the case when one child has multiple parents
                    // have confirmed with Tyler that it wouldn't happen
                    // lets send an alert to DataDog if incase it happens
                }
            });
        }
    });
    let formattedPaths = {};
    const INFINITE_MAX_LOOP_COUNT = 50000;
    let count = 1;
    // third pass, format the path  to look like
    // Root > Node 1 > Node 2
    Object.keys(path).forEach(key => {
        var _a, _b, _c;
        let stack = [];
        //  Lets reverse the linked list using stack
        if (path[key]) {
            let node = (_a = path[key]) === null || _a === void 0 ? void 0 : _a.head;
            //  we add count < INFINITE_MAX_LOOP_COUNT to make sure the code exits after certain point
            //  Ref: https://eightify.app/summary/artificial-intelligence-and-design/nasa-s-space-proof-code-writing-techniques
            //  ```NASA avoids using recursion in their code and gives all loops a fixed upper bound to prevent crashes and ensure control flow.```
            while (node && count < INFINITE_MAX_LOOP_COUNT) {
                if (((_b = node === null || node === void 0 ? void 0 : node.data) === null || _b === void 0 ? void 0 : _b.key) !== key) {
                    stack.push((_c = node === null || node === void 0 ? void 0 : node.data) === null || _c === void 0 ? void 0 : _c.name);
                }
                node = node.next;
            }
        }
        let formattedPath = stack.pop();
        while (stack.length > 0) {
            formattedPath += ' > ' + stack.pop();
        }
        formattedPaths[key] = formattedPath;
    });
    return formattedPaths;
};
