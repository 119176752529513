import React from 'react';
import { addColumnSortLabels } from '@/presentation/components/commons/labels';
import { IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH, IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE, IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT, IDENTITY_AUTHENTICATION_TYPE_DEFAULT_IDENTITY, IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY, PATH_IDENTITIES, PATHS } from '@/presentation/common-utils/constants';
import RegularLink from '@/presentation/components/links/regular-link';
import ButtonLink from '@/presentation/components/links/button-link';
import { sortingComparatorByKey } from '@/lib/misc/utils';
import { descriptionFilteringProperty, idFilteringProperty, nameFilteringProperty, tagsFilteringProperty, userDetailsCreatedColumnDefinition, userDetailsCreatedFilteringProperty, userDetailsUpdatedColumnDefinition, userDetailsUpdatedFilteringProperty } from '@/presentation/pages/common-config';
import { getIdentifierLabel } from "./constants";
const indentifierLabelObject = getIdentifierLabel();
export const IdentitiesListCardsColumnDefinitions = addColumnSortLabels([
    {
        id: 'id',
        header: 'ID',
        cell: item => {
            const link = String(PATH_IDENTITIES.VIEW) + '/' + item.id;
            return React.createElement(RegularLink, { to: link, label: item.id });
        },
        sortingField: 'id',
        width: 120
    },
    {
        id: 'name',
        sortingField: 'name',
        header: 'Name',
        cell: (item) => {
            return item === null || item === void 0 ? void 0 : item.name;
        },
    },
    {
        id: 'description',
        sortingField: 'description',
        header: 'Description',
        cell: item => item === null || item === void 0 ? void 0 : item.description,
    },
    {
        id: 'identifier',
        header: 'Identifier',
        cell: item => {
            return indentifierLabelObject[item.identifier];
        },
        sortingField: 'identifier',
        width: 240
    },
    {
        id: 'configDetails',
        header: 'Details',
        cell: (item) => {
            return (item === null || item === void 0 ? void 0 : item.configDetails) || '';
        },
        sortingField: 'configDetails',
        width: 240
    },
    {
        id: 'hiddenID',
        header: 'ID',
        cell: item => {
            const link = String(PATH_IDENTITIES.VIEW) + '/' + item.id;
            return React.createElement(RegularLink, { to: link, label: item.id });
        },
        sortingField: 'id',
        width: 120
    },
    {
        id: 'attachedProfilesCount',
        header: 'Attached Profiles (#)',
        cell: item => item === null || item === void 0 ? void 0 : item.attachedProfilesCount,
        sortingField: 'attachedProfilesCount',
        sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'attachedProfilesCount'),
    },
    {
        id: 'attachedRulesCount',
        header: 'Attached Rules (#)',
        cell: item => item === null || item === void 0 ? void 0 : item.attachedRulesCount,
        sortingField: 'attachedRulesCount',
        sortingComparator: (a, b) => sortingComparatorByKey(a, b, 'attachedRulesCount'),
    },
    ...userDetailsCreatedColumnDefinition,
    ...userDetailsUpdatedColumnDefinition,
    {
        id: 'table-actions',
        header: '',
        cell: item => {
            const link = PATHS.IDENTITIES + '/' + item.id + '/clone';
            return React.createElement(ButtonLink, { to: link, key: `copy-button-${item.id}`, variant: "inline-icon", iconName: "copy", ariaLabel: 'copy-button', tooltipClass: `identities-list-clone-button-${item.id}`, tooltipMessage: 'Clone' });
        },
    }
]);
export const IdentitiesListTablePreferences = {
    pageSize: 30,
    visibleContent: ['id', 'name', 'description', 'identifier', 'attachedProfilesCount', 'attachedRulesCount', 'table-actions'],
    wrapLines: false,
    custom: {
        autoResize: true
    }
};
export const IdentitiesListTableVisibleContentOptions = [
    {
        label: null,
        options: [
            { id: 'id', label: 'ID', editable: false },
            { id: 'name', label: 'Name', editable: false },
            { id: 'description', label: 'Description', editable: false },
            { id: 'identifier', label: 'Identifier', editable: true },
            { id: 'configDetails', label: 'Details', editable: true },
            { id: 'attachedProfilesCount', label: 'Attached Profiles (#)', editable: true },
            { id: 'attachedRulesCount', label: 'Attached Rules (#)', editable: true },
            { id: 'created_by', label: 'Created By', editable: true },
            { id: 'created_at', label: 'Created', editable: true },
            { id: 'updated_by', label: 'Updated By', editable: true },
            { id: 'updated_at', label: 'Updated', editable: true },
        ]
    }
];
export const IdentitiesListTablePageSizeOptions = [
    { value: 10, label: '10 Identities' },
    { value: 30, label: '30 Identities' },
    { value: 50, label: '50 Identities' }
];
export const IdentitiesListPropertyFilterFilteringProperties = [
    idFilteringProperty,
    nameFilteringProperty,
    descriptionFilteringProperty,
    {
        key: 'identifierReadableName',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Identifier',
        groupValuesLabel: 'Identifier values'
    },
    {
        key: 'configDetails',
        operators: ['=', '!=', ':', '!:'],
        propertyLabel: 'Details',
        groupValuesLabel: 'Details values'
    },
    {
        key: 'attachedProfilesCount',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Attached Profiles (#)',
        groupValuesLabel: 'Attached Profiles (#) values'
    },
    {
        key: 'attachedRulesCount',
        operators: ['=', '!=', '<', '<=', '>', '>='],
        propertyLabel: 'Attached Rules (#)',
        groupValuesLabel: 'Attached Rules(#) values'
    },
    userDetailsCreatedFilteringProperty,
    userDetailsUpdatedFilteringProperty,
    tagsFilteringProperty
];
export const IdentitiesListTablePropertyFilterFilteringOptions = [
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY]
    },
    {
        propertyKey: 'identifierReadableName',
        value: indentifierLabelObject[IDENTITY_AUTHENTICATION_TYPE_DEFAULT_IDENTITY]
    }
];
