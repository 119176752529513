import { capitalizeFirst } from "@/lib/misc/utils";
export const DEFAULT_TABLE_INITIAL_PAGE_SIZE = 10;
export const PROVIDER_AWS = 'AWS';
export const PROVIDER_AZURE = 'AZURE';
export const PROVIDER_GCP = 'GCP';
export const SERVICE_S3 = 'S3';
export const SERVICE_CLOUDFORMATION = 'CLOUDFORMATION';
export const IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH = 'BASIC_AUTH';
export const IDENTITY_AUTHENTICATION_TYPE_CIDR_RANGE = 'CIDR_RANGE';
export const IDENTITY_AUTHENTICATION_TYPE_CLOUD_TENANT = 'CLOUD_TENANT';
export const IDENTITY_AUTHENTICATION_TYPE_USER_IDENTITY = 'USER_IDENTITY';
export const IDENTITY_AUTHENTICATION_TYPE_DEFAULT_IDENTITY = 'DEFAULT_IDENTITY';
export const IDENTITY_TYPE = 'CIDR_RANGE';
export const DEPLOYMENT_SUPER_SELECT_DEFAULT_OPTION = 'HYBRID';
export const LOG_LEVEL_SUPER_SELECT_DEFAULT_OPTION = 'INFO';
export const LOG_LEVEL_SUPER_SELECT_DEBUG_OPTION = 'DEBUG';
export const LEARNING_MODE_SUPER_SELECT_ENABLED_OPTION = 'ENABLED';
export const LEARNING_MODE_SUPER_SELECT_DISABLED_OPTION = 'DISABLED';
export const AUTHENTICATION_SUPER_SELECT_DEFAULT_OPTION = 'true';
export const IDENTITY_TYPE_AUTHENTICATION_TYPE_SUPER_SELECT_DEFAULT_OPTION = IDENTITY_AUTHENTICATION_TYPE_BASIC_AUTH;
export const NEW_LINE = '\n';
export const OPEN_BRACKET = '{';
export const CLOSE_BRACKET = '}';
export const OPEN_SQRBRACKET = '[';
export const CLOSE_SQRBRACKET = ']';
export const INDENT_TAG = '\t';
export const INDENT_TAGS = '\t\t';
export const WILDCARD = '*';
export const DEFAULT_NOTIFICATION_MONITOR_INTERVAL = 1;
export const CONFIG_VERSION = 'v1';
export var PATHS;
(function (PATHS) {
    PATHS["HOME"] = "/";
    PATHS["OOPS"] = "/oops";
    PATHS["PROXIES"] = "/proxies";
    PATHS["IDENTITIES"] = "/identities";
    PATHS["PROFILES"] = "/profiles";
    PATHS["RULES"] = "/rules";
    PATHS["POLICIES"] = "/policies";
    PATHS["DASHBOARD"] = "/";
    PATHS["MONITORING"] = "/monitoring";
    PATHS["DOCS"] = "/docs";
    PATHS["REPORTING"] = "/reporting";
    PATHS["MYPROFILE"] = "/my-profile";
    PATHS["SETTINGS"] = "/settings";
    PATHS["LOGOUT"] = "/logout";
    PATHS["DEPLOYMENTS"] = "/deployments";
    PATHS["NOTIFICATIONS"] = "/notifications";
    PATHS["ONBOARDING"] = "/onboarding";
    PATHS["SUPPORT"] = "/support";
})(PATHS || (PATHS = {}));
export var PATHS_SINGULAR;
(function (PATHS_SINGULAR) {
    PATHS_SINGULAR["IDENTITY"] = "/identity";
    PATHS_SINGULAR["PROFILE"] = "/profile";
    PATHS_SINGULAR["RULE"] = "/rule";
})(PATHS_SINGULAR || (PATHS_SINGULAR = {}));
//  this is used to transform the current active path
//  to Page Name that is shown on top nav
export const makePathPageName = (path) => {
    const pathWithoutSlash = path.toString().slice(1);
    if (!pathWithoutSlash) {
        return 'Dashboard';
    }
    //  Eg. /profiles --> Profiles
    // /my-profile --> My Profile
    if (pathWithoutSlash.includes('-')) {
        const parts = pathWithoutSlash.split('-');
        const initialValue = parts[0];
        return parts.reduce((previousValue, currentValue, currentIndex) => {
            const capitalizedCurrentValue = capitalizeFirst(currentValue);
            return currentIndex === 0 ? capitalizedCurrentValue : capitalizeFirst(previousValue) + ' ' + capitalizedCurrentValue;
        }, initialValue);
    }
    return capitalizeFirst(pathWithoutSlash);
};
export var PATH_DOCUMENTATION;
(function (PATH_DOCUMENTATION) {
    PATH_DOCUMENTATION["GETTING_STRATED"] = "/docs/quick-start-guide";
    PATH_DOCUMENTATION["POLICY_REFERENCE"] = "/docs/kivera-policy-reference";
    PATH_DOCUMENTATION["RULES_LIBRARY"] = "/docs/rules-library";
    PATH_DOCUMENTATION["MULTI_FACTOR_AUTHENTICATION"] = "/docs/multi-factor-authentication";
})(PATH_DOCUMENTATION || (PATH_DOCUMENTATION = {}));
export var PATH_PROXIES;
(function (PATH_PROXIES) {
    PATH_PROXIES["CREATE"] = "/proxies/create";
    PATH_PROXIES["EDIT"] = "/proxies/edit";
    PATH_PROXIES["CLONE"] = "/proxies/clone";
    PATH_PROXIES["VIEW"] = "/proxies";
})(PATH_PROXIES || (PATH_PROXIES = {}));
export var PATH_PROXY_DEPLOYMENTS;
(function (PATH_PROXY_DEPLOYMENTS) {
    PATH_PROXY_DEPLOYMENTS["CREATE"] = "/deployments/create";
})(PATH_PROXY_DEPLOYMENTS || (PATH_PROXY_DEPLOYMENTS = {}));
export var PATH_IDENTITIES;
(function (PATH_IDENTITIES) {
    PATH_IDENTITIES["CREATE"] = "/identities/create";
    PATH_IDENTITIES["EDIT"] = "/identities/edit";
    PATH_IDENTITIES["CLONE"] = "/identities/clone";
    PATH_IDENTITIES["VIEW"] = "/identities";
})(PATH_IDENTITIES || (PATH_IDENTITIES = {}));
export var PATH_PROFILES;
(function (PATH_PROFILES) {
    PATH_PROFILES["CREATE"] = "/profiles/create";
    PATH_PROFILES["EDIT"] = "/profiles/edit";
    PATH_PROFILES["CLONE"] = "/profiles/clone";
    PATH_PROFILES["VIEW"] = "/profiles";
})(PATH_PROFILES || (PATH_PROFILES = {}));
export var PATH_POLICIES;
(function (PATH_POLICIES) {
    PATH_POLICIES["CREATE"] = "/policies/create";
    PATH_POLICIES["EDIT"] = "/policies/edit";
    PATH_POLICIES["CLONE"] = "/policies/clone";
    PATH_POLICIES["VIEW"] = "/policies";
})(PATH_POLICIES || (PATH_POLICIES = {}));
export var PATH_RULES;
(function (PATH_RULES) {
    PATH_RULES["CREATE"] = "/rules/create";
    PATH_RULES["EDIT"] = "/rules/edit";
    PATH_RULES["CLONE"] = "/rules/clone";
    PATH_RULES["VIEW"] = "/rules";
    PATH_RULES["SIMULATOR"] = "simulator";
    PATH_RULES["LIBRARY"] = "/rules/library";
})(PATH_RULES || (PATH_RULES = {}));
export const makeRuleSimulatorPath = (id) => {
    return `${PATH_RULES.VIEW}/${id}/${PATH_RULES.SIMULATOR}`;
};
export var PATH_SETTINGS;
(function (PATH_SETTINGS) {
    PATH_SETTINGS["EDIT"] = "/settings/edit";
    PATH_SETTINGS["ORG_POLICY_FUNCS_EDIT"] = "/settings/org-policy-functions/edit";
    PATH_SETTINGS["NOTIFICATIONS_DESTINATIONS_CREATE"] = "/settings/notifications-destinations/create";
    PATH_SETTINGS["CLOUD_TENANTS"] = "/settings/cloud-tenant-structures";
    PATH_SETTINGS["CLOUD_TENANTS_CREATE"] = "/settings/cloud-tenant-structures/create";
    PATH_SETTINGS["DOMAIN_ACLS_LIST"] = "/settings#domain-acls";
    PATH_SETTINGS["DOMAIN_ACLS"] = "/settings/domain-acls";
    PATH_SETTINGS["DOMAIN_ACLS_CREATE"] = "/settings/domain-acls/create";
})(PATH_SETTINGS || (PATH_SETTINGS = {}));
export var PATH_NOTIFICATIONS;
(function (PATH_NOTIFICATIONS) {
    PATH_NOTIFICATIONS["CREATE"] = "/notifications/create";
})(PATH_NOTIFICATIONS || (PATH_NOTIFICATIONS = {}));
export var DATE_DISPLAY_FORMATS;
(function (DATE_DISPLAY_FORMATS) {
    DATE_DISPLAY_FORMATS["DATE_ONLY"] = "date_only";
    DATE_DISPLAY_FORMATS["DATE_TIME"] = "date_timestamp";
})(DATE_DISPLAY_FORMATS || (DATE_DISPLAY_FORMATS = {}));
export var TABLE_FILTERS_SESSION_STORAGE;
(function (TABLE_FILTERS_SESSION_STORAGE) {
    TABLE_FILTERS_SESSION_STORAGE["PROXIES_LIST"] = "proxies-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["IDENTITIES_LIST"] = "identities-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["PROFILES_LIST"] = "profiles-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["RULES_LIST"] = "rules-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["PROXIES_VIEW_IDENTITIES_LIST"] = "proxies-view-identities-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["IDENTITIES_VIEW_PROFILES_LIST"] = "identities-view-profiles-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["PROFILES_VIEW_RULES_LIST"] = "profiles-view-rules-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["PROXIES_FORM_IDENTITIES_LIST"] = "proxies-form-identities-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["IDENTITIES_FORM_PROFILES_LIST"] = "identities-form-profiles-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["PROFILES_FORM_RULES_LIST"] = "profiles-form-rules-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["MONITORING_LIST"] = "monitoring-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["NOTIFICATIONS_LIST"] = "notifications-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_USER_ACCESS_LIST"] = "settings-user-access-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_GLOBAL_SERVICES_LIST"] = "settings-global-services-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_AUDIT_LOGS_LIST"] = "settings-audit-logs-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_NOTIFICATIONS_DESTINATIONS_LIST"] = "notifications-destinations-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_CLOUD_TENANTS_LIST"] = "settings-cloud-tenants-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_DOMAIN_ACLS_LIST"] = "settings-domain-acls-list-filters";
    TABLE_FILTERS_SESSION_STORAGE["SETTINGS_DOMAIN_ACLS_LIST_VIEW"] = "settings-domain-acls-list-view-filters";
    TABLE_FILTERS_SESSION_STORAGE["MY_PROFILE_LIST_API_KEYS"] = "my-profiles-list-api-keys-filters";
    TABLE_FILTERS_SESSION_STORAGE["MY_PROFILE_LIST_ORGANIZATIONS"] = "my-profiles-list-organizations-filters";
})(TABLE_FILTERS_SESSION_STORAGE || (TABLE_FILTERS_SESSION_STORAGE = {}));
export var TABLE_SORTING_SESSION_STORAGE;
(function (TABLE_SORTING_SESSION_STORAGE) {
    TABLE_SORTING_SESSION_STORAGE["PROXIES_LIST"] = "proxies-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["IDENTITIES_LIST"] = "identities-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["PROFILES_LIST"] = "profiles-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["RULES_LIST"] = "rules-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["PROXIES_VIEW_IDENTITIES_LIST"] = "proxies-view-identities-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["IDENTITIES_VIEW_PROFILES_LIST"] = "identities-view-profiles-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["PROFILES_VIEW_RULES_LIST"] = "profiles-view-rules-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["PROXIES_FORM_IDENTITIES_LIST"] = "proxies-form-identities-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["IDENTITIES_FORM_PROFILES_LIST"] = "identities-form-profiles-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["PROFILES_FORM_RULES_LIST"] = "profiles-form-rules-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["MONITORING_LIST"] = "monitoring-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["NOTIFICATIONS_LIST"] = "notifications-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_USER_ACCESS_LIST"] = "settings-user-access-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_GLOBAL_SERVICES_LIST"] = "settings-global-services-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_AUDIT_LOGS_LIST"] = "settings-audit-logs-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_NOTIFICATIONS_DESTINATIONS_LIST"] = "notifications-destinations-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_CLOUD_TENANTS_LIST"] = "settings-cloud-tenants-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_DOMAIN_ACLS_LIST"] = "settings-domain-acls-list-sorting";
    TABLE_SORTING_SESSION_STORAGE["SETTINGS_DOMAIN_ACLS_LIST_VIEW"] = "settings-domain-acls-list-view-sorting";
    TABLE_SORTING_SESSION_STORAGE["MY_PROFILE_LIST_API_KEYS"] = "my-profiles-list-api-keys-sorting";
    TABLE_SORTING_SESSION_STORAGE["MY_PROFILE_LIST_ORGANIZATIONS"] = "my-profiles-list-organizations-sorting";
})(TABLE_SORTING_SESSION_STORAGE || (TABLE_SORTING_SESSION_STORAGE = {}));
export var PROPERTY_FILTER_OPERATOR_VALUES;
(function (PROPERTY_FILTER_OPERATOR_VALUES) {
    PROPERTY_FILTER_OPERATOR_VALUES["EQUAL_TO"] = "equal_to";
    PROPERTY_FILTER_OPERATOR_VALUES["NOT_EQUAL_TO"] = "not_equal_to";
    PROPERTY_FILTER_OPERATOR_VALUES["CONTAINS"] = "contains";
    PROPERTY_FILTER_OPERATOR_VALUES["DOES_NOT_CONTAIN"] = "does_not_contain";
    PROPERTY_FILTER_OPERATOR_VALUES["LESS_THAN"] = "less_than";
    PROPERTY_FILTER_OPERATOR_VALUES["LESS_THAN_OR_EQUAL_TO"] = "less_than_or_equal_to";
    PROPERTY_FILTER_OPERATOR_VALUES["GREATER_THAN"] = "greater_than";
    PROPERTY_FILTER_OPERATOR_VALUES["GREATER_THAN_OR_EQUAL_TO"] = "greater_than_or_equal_to";
})(PROPERTY_FILTER_OPERATOR_VALUES || (PROPERTY_FILTER_OPERATOR_VALUES = {}));
