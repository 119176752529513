import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useRulesHandlers } from '@/presentation/providers';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { Box, Button, ColumnLayout, Container, Header, StatusIndicator, Icon, SpaceBetween, ExpandableSection, Badge, Popover, } from '@cloudscape-design/components-themed/components';
import { getRandomUUID, toPascalCase } from '@/lib/misc/utils';
import { PATHS, PATH_RULES } from '@/presentation/common-utils/constants';
import Skeleton from 'react-loading-skeleton';
import ContentWrapper from '@/presentation/components/content-wrapper';
import DeleteModal from '@/presentation/components/modal/delete-modal';
import { makeNotification, getLogoAsUrl, } from '@/presentation/pages';
import { RulesDeleteStrings, RulesDeleteShowProp, FallbackRuleDetails } from '@/presentation/pages/rules/rules-types';
import ShowTags from '@/presentation/components/tags/show-tags';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import ShowAttributeEditor from "@/presentation/components/attribute-editor/show-attribute-editor";
import CodeEditor from "@/presentation/components/code-editor/code-editor";
import { useIsMounted } from '@/presentation/hooks';
import ContentLayoutWrapper from '@/presentation/components/content-wrapper/content-layout-wrapper';
const makeBadges = (configElement = []) => {
    const badges = configElement.map(ele => {
        return React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, ele);
    });
    return React.createElement(SpaceBetween, { size: 's', direction: 'horizontal' }, badges);
};
const getSplitPanelRuleDetails = (props, actionBadges) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const riskRating = toPascalCase(String((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.riskRating) === null || _c === void 0 ? void 0 : _c.label).replaceAll('_', ' '));
    return React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" },
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                React.createElement(Box, null, (_e = (_d = props === null || props === void 0 ? void 0 : props.rule) === null || _d === void 0 ? void 0 : _d.details) === null || _e === void 0 ? void 0 : _e.description)),
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Provider"),
                React.createElement(Box, null, React.createElement("span", { className: 'pl-1' },
                    React.createElement(Icon, { size: "medium", url: getLogoAsUrl((_h = (_g = (_f = props === null || props === void 0 ? void 0 : props.rule) === null || _f === void 0 ? void 0 : _f.details) === null || _g === void 0 ? void 0 : _g.provider) === null || _h === void 0 ? void 0 : _h.label), alt: ((_l = (_k = (_j = props === null || props === void 0 ? void 0 : props.rule) === null || _j === void 0 ? void 0 : _j.details) === null || _k === void 0 ? void 0 : _k.provider) === null || _l === void 0 ? void 0 : _l.label) || '' }))))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Service"),
                React.createElement(Box, null, (_p = (_o = (_m = props === null || props === void 0 ? void 0 : props.rule) === null || _m === void 0 ? void 0 : _m.details) === null || _o === void 0 ? void 0 : _o.service) === null || _p === void 0 ? void 0 : _p.label)),
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Actions"),
                React.createElement(Box, null, actionBadges))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Risk Rating"),
                React.createElement(Box, null, riskRating != '[null]' ?
                    React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, riskRating) : '-'))));
};
const getRuleDetailsConfig = (props, actionBadges) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const riskRating = toPascalCase(String((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.riskRating) === null || _c === void 0 ? void 0 : _c.label).replaceAll('_', ' '));
    return React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                React.createElement(Box, null, (_e = (_d = props === null || props === void 0 ? void 0 : props.rule) === null || _d === void 0 ? void 0 : _d.details) === null || _e === void 0 ? void 0 : _e.description))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Provider"),
                React.createElement(Box, null, React.createElement("span", { className: 'pl-1' },
                    React.createElement(Icon, { size: "medium", url: getLogoAsUrl((_h = (_g = (_f = props === null || props === void 0 ? void 0 : props.rule) === null || _f === void 0 ? void 0 : _f.details) === null || _g === void 0 ? void 0 : _g.provider) === null || _h === void 0 ? void 0 : _h.label), alt: ((_l = (_k = (_j = props === null || props === void 0 ? void 0 : props.rule) === null || _j === void 0 ? void 0 : _j.details) === null || _k === void 0 ? void 0 : _k.provider) === null || _l === void 0 ? void 0 : _l.label) || '' }))))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Service"),
                React.createElement(Box, null, (_p = (_o = (_m = props === null || props === void 0 ? void 0 : props.rule) === null || _m === void 0 ? void 0 : _m.details) === null || _o === void 0 ? void 0 : _o.service) === null || _p === void 0 ? void 0 : _p.label))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Actions"),
                React.createElement(Box, null, actionBadges))),
        React.createElement(SpaceBetween, { size: "l" },
            React.createElement(Box, null,
                React.createElement(Box, { variant: "awsui-key-label" }, "Risk Rating"),
                React.createElement(Box, null, ((_s = (_r = (_q = props === null || props === void 0 ? void 0 : props.rule) === null || _q === void 0 ? void 0 : _q.details) === null || _r === void 0 ? void 0 : _r.riskRating) === null || _s === void 0 ? void 0 : _s.label) ?
                    React.createElement(Badge, { key: getRandomUUID(), color: 'grey' }, riskRating) : '-'))));
};
export const RuleDetails = (props) => {
    var _a, _b, _c;
    const { initialConfig, logBody, enforceRule, cfnScan, service } = (_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.details) !== null && _b !== void 0 ? _b : FallbackRuleDetails;
    const { actions, endpoints, methods, paths } = JSON.parse(initialConfig);
    const isCFNCheck = ((_c = service === null || service === void 0 ? void 0 : service.label) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 's3';
    const actionBadges = makeBadges(actions);
    const endpointsBadges = makeBadges(endpoints);
    const methodsBadges = makeBadges(methods);
    const pathsBadges = makeBadges(paths);
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Rule Details") },
            React.createElement(SpaceBetween, { size: 'l' },
                (props === null || props === void 0 ? void 0 : props.splitPanelFormat) ?
                    getSplitPanelRuleDetails(props, actionBadges) :
                    getRuleDetailsConfig(props, actionBadges),
                React.createElement(ExpandableSection, { headerText: "Advanced Configuration" },
                    React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
                        React.createElement(SpaceBetween, { size: "l" },
                            React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Endpoints"),
                                React.createElement(Box, null, endpointsBadges)),
                            React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Log Body"),
                                React.createElement(Box, null, logBody
                                    ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                                    : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled")))),
                        React.createElement(SpaceBetween, { size: "l" },
                            React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Methods"),
                                React.createElement(Box, null, methodsBadges)),
                            React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Enforce Rule"),
                                React.createElement(Box, null, enforceRule
                                    ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                                    : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled")))),
                        React.createElement(SpaceBetween, { size: "l" },
                            React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Paths"),
                                React.createElement(Box, null, pathsBadges)),
                            isCFNCheck && (React.createElement(Box, null,
                                React.createElement(Box, { variant: "awsui-key-label" }, "Cloudformation Scan"),
                                React.createElement(Box, null, cfnScan
                                    ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                                    : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled"))))))))));
};
export const RulePolicies = (props) => {
    var _a, _b;
    const navigate = useNavigate();
    const policyData = ((_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.policyConfig) === null || _b === void 0 ? void 0 : _b.policy) || '';
    const actionButtons = (React.createElement(Popover, { size: "small", position: "top", triggerType: "custom", dismissButton: false, content: 'Policy copied to clipboard' },
        React.createElement(SpaceBetween, { size: "xs", direction: 'horizontal' },
            React.createElement(Button, { onClick: () => {
                    navigator.clipboard.writeText(policyData);
                } }, "Copy"),
            (props === null || props === void 0 ? void 0 : props.viewOnly) ?
                null :
                React.createElement(Button, { onClick: () => {
                        var _a, _b;
                        navigate(PATHS.RULES + '/' + ((_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.id) + '/edit' + '#open-policy-editor');
                    } }, "Edit"))));
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2", actions: actionButtons }, "Policy") },
            React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
                React.createElement(CodeEditor, { code: policyData, height: 400, heightType: 'px', readOnly: true, inverse: false, language: 'rego' }))));
};
export const RuleTags = (props) => {
    var _a, _b, _c, _d, _e;
    const tags = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.tags) === null || _b === void 0 ? void 0 : _b.editorTags) !== null && _c !== void 0 ? _c : [];
    const currentId = (_e = (_d = props === null || props === void 0 ? void 0 : props.rule) === null || _d === void 0 ? void 0 : _d.details) === null || _e === void 0 ? void 0 : _e.id;
    return React.createElement(ShowTags, { tags: tags, id: currentId, resource: "rule", basePath: PATHS.RULES, noButton: props === null || props === void 0 ? void 0 : props.noTagsButton });
};
export const RuleComplianceMappings = (props) => {
    var _a, _b, _c, _d, _e;
    const mappings = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.rule) === null || _a === void 0 ? void 0 : _a.compliance) === null || _b === void 0 ? void 0 : _b.mappings) !== null && _c !== void 0 ? _c : [];
    const currentId = (_e = (_d = props === null || props === void 0 ? void 0 : props.rule) === null || _d === void 0 ? void 0 : _d.details) === null || _e === void 0 ? void 0 : _e.id;
    return React.createElement(ShowAttributeEditor, { mappings: mappings, id: currentId, resource: "rule", basePath: PATHS.RULES, noButton: props === null || props === void 0 ? void 0 : props.noTagsButton });
};
const RulesView = (props) => {
    var _a, _b, _c, _d;
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [formattedRule, setFormattedRule] = useState(undefined);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    const { rules } = useRulesHandlers();
    const rulesHandler = rules;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const importedNum = (_b = (_a = formattedRule === null || formattedRule === void 0 ? void 0 : formattedRule.details) === null || _a === void 0 ? void 0 : _a.importedFrom) !== null && _b !== void 0 ? _b : 0;
    const makeSuccessNotification = (message) => {
        const msg = 'Rule deleted successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to delete. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onDeleteConfirm = () => {
        var _a;
        const toBeDeleted = parseInt(params === null || params === void 0 ? void 0 : params.ruleID, 10);
        (_a = rulesHandler.deleteEntity(toBeDeleted)) === null || _a === void 0 ? void 0 : _a.then((result) => {
            var _a;
            // if success, unselect the items
            if (((_a = result === null || result === void 0 ? void 0 : result.delete_Rules) === null || _a === void 0 ? void 0 : _a.affected_rows) > 0) {
                pushNotifications(makeSuccessNotification());
                navigate(PATHS.RULES);
            }
            else { // else show error notification
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            // drop the modal and show success or error
            if (isMounted.current) {
                setShowDeleteModal(false);
            }
        });
    };
    useEffect(() => {
        var _a, _b;
        if (((_b = (_a = props === null || props === void 0 ? void 0 : props.rulesInfo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.id) && isMounted.current) {
            setFormattedRule(props.rulesInfo);
            setItems([props.rulesInfo]);
            setLoading(false);
        }
    }, [props]);
    if (!(props === null || props === void 0 ? void 0 : props.rulesInfo) || loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    const makeSimulatorPath = () => {
        return (location === null || location === void 0 ? void 0 : location.pathname) + '/' + PATH_RULES.SIMULATOR;
    };
    const headerInfo = importedNum ? React.createElement(Badge, { color: "grey" },
        "Imported from KRL:",
        importedNum) : '';
    return (React.createElement(ContentWrapper, { testid: "rule-view-page" },
        React.createElement(ContentLayoutWrapper, { header: React.createElement("div", { id: "rules-view-header" },
                React.createElement(Header, { variant: "h1", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                        React.createElement(Button, { variant: 'normal', "data-testid": "click-to-simulate-this-rule", onClick: () => navigate(makeSimulatorPath()) }, "Run Simulator"),
                        React.createElement(Button, { "data-testid": "click-to-delete-this-rule", onClick: onDeleteInit }, "Delete"),
                        React.createElement(Button, { variant: "primary", "ata-testid": "click-to-edit-this-rule", onClick: () => {
                                navigate(PATHS.RULES + '/' + (params === null || params === void 0 ? void 0 : params.ruleID) + '/edit');
                            } }, "Edit")), info: headerInfo },
                    "Rule:", (_d = (_c = formattedRule === null || formattedRule === void 0 ? void 0 : formattedRule.details) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : '')) },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(RuleDetails, { rule: formattedRule, rulesHandler: rulesHandler }),
                React.createElement(RulePolicies, { rule: formattedRule, rulesHandler: rulesHandler }),
                React.createElement(RuleComplianceMappings, { rule: formattedRule, rulesHandler: rulesHandler }),
                React.createElement(RuleTags, { rule: formattedRule, rulesHandler: rulesHandler }),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: items, deleteModalStrings: RulesDeleteStrings, itemDeleteShowProp: RulesDeleteShowProp })))));
};
export default RulesView;
