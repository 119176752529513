import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useProxiesHandlers } from "@/presentation/providers";
import { useParams } from "react-router-dom";
import { formatExistingTags, getLogoAsUrl, getProviderDescription } from "../common";
import ContentWrapper from "@/presentation/components/content-wrapper";
import { HeaderStrings, proxySettingsErrorActionOptions } from "@/presentation/pages/proxies/proxies-types";
import ProxiesForm from "@/presentation/pages/proxies/proxies-form";
import { Header } from "@cloudscape-design/components-themed/components";
import moment from 'moment';
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import ContentFormLayoutWrapper from '@/presentation/components/content-wrapper/content-form-layout-wrapper';
const ProxiesFormWrapper = (props) => {
    const { setErrorStatus } = useErrorBoundaryContext();
    const [loading, setLoading] = useState(false);
    const [listIdentities, setListIdentities] = useState([]);
    const [domainACLs, setDomainACLs] = useState([]);
    const [providersList, setProvidersList] = useState();
    const [proxyTagsAndIdentities, setProxyTagsAndIdentities] = useState();
    const { proxies, identities, providers, domainACL } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const identitiesHandler = identities;
    const providersHandler = providers;
    const domainACLHandler = domainACL;
    const params = useParams();
    const [stepInfo, setStepInfo] = useState(undefined);
    const isMounted = useIsMounted();
    const makeLogLevel = (isDebug) => {
        if (isDebug) {
            return {
                label: 'Debug',
                value: 'DEBUG'
            };
        }
        return {
            label: 'Info',
            value: 'INFO'
        };
    };
    const fetchDomainHandlerList = () => {
        domainACLHandler.list().then(listAcls => {
            if (isMounted.current) {
                setDomainACLs(domainACLHandler.getFormattedDomainACLs(listAcls));
            }
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            providersHandler.listEntity().then((providers) => {
                if (isMounted.current) {
                    setProvidersList(providers);
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        });
    };
    const fetchIdentitiesAndProviders = (proxy) => {
        if (isMounted.current) {
            setLoading(true);
        }
        identitiesHandler.listEntity().then((identities) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            const { name = '', id = 0, description = '', ProxyIdentities = [], ProxySettings = [], ProxyDomainAcls = [], ProxyProviders = [], tags = [] } = (_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) !== null && _a !== void 0 ? _a : {};
            const { default_identity_id = null, allow_noncloud_traffic = false, default_mode = '', learning_mode = false, debug = false, rego_raise_error = false, on_error_action = undefined } = (ProxySettings === null || ProxySettings === void 0 ? void 0 : ProxySettings[0]) || {};
            const proxyIdentities = (_b = ProxyIdentities === null || ProxyIdentities === void 0 ? void 0 : ProxyIdentities.map(identity => identity === null || identity === void 0 ? void 0 : identity.identity_id)) === null || _b === void 0 ? void 0 : _b.filter(id => !!id);
            const defaultIdentityID = default_identity_id ? String(default_identity_id) : null;
            const domainACLID = String((_e = (_d = (_c = ProxyDomainAcls === null || ProxyDomainAcls === void 0 ? void 0 : ProxyDomainAcls[0]) === null || _c === void 0 ? void 0 : _c.DomainAcl) === null || _d === void 0 ? void 0 : _d.id) !== null && _e !== void 0 ? _e : '');
            const domainACLName = String((_h = (_g = (_f = ProxyDomainAcls === null || ProxyDomainAcls === void 0 ? void 0 : ProxyDomainAcls[0]) === null || _f === void 0 ? void 0 : _f.DomainAcl) === null || _g === void 0 ? void 0 : _g.name) !== null && _h !== void 0 ? _h : '');
            const defaultIdentityName = String((_l = (_k = (_j = ProxySettings[0]) === null || _j === void 0 ? void 0 : _j.Identity) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : '');
            const formattedIdentities = identitiesHandler.getSelectableInMemoryFormattedEntities(identities, proxyIdentities, defaultIdentityID);
            if (isMounted.current) {
                setListIdentities(formattedIdentities);
            }
            const selectedProviders = ProxyProviders.length > 0 ? ProxyProviders
                .filter(provider => provider === null || provider === void 0 ? void 0 : provider.enabled)
                .map(provider => {
                var _a, _b, _c;
                return {
                    label: (_a = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _a === void 0 ? void 0 : _a.name,
                    value: String(provider === null || provider === void 0 ? void 0 : provider.provider_id),
                    iconUrl: getLogoAsUrl((_b = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _b === void 0 ? void 0 : _b.name),
                    description: getProviderDescription((_c = provider === null || provider === void 0 ? void 0 : provider.Provider) === null || _c === void 0 ? void 0 : _c.name)
                };
            }) : [];
            const existingTags = formatExistingTags(tags);
            const currentTimestamp = moment().format('YYYYMMDDHHmmss');
            const actualName = name;
            const modifiedName = props.isClone ? `${actualName}-${currentTimestamp}` : actualName;
            const proxiesCreateDefaultStepInfo = {
                details: {
                    id,
                    name: modifiedName,
                    description,
                    providers: selectedProviders,
                    nonProviderTraffic: allow_noncloud_traffic,
                    domainACLs: !domainACLID ? {} : {
                        label: domainACLName,
                        value: domainACLID
                    },
                    defaultMode: proxiesHandler.makeDefaultMode(default_mode),
                    learningMode: learning_mode,
                    logLevel: makeLogLevel(debug),
                    regoRaiseError: rego_raise_error,
                    errorAction: on_error_action ? (_m = proxySettingsErrorActionOptions.filter(action => action.value === on_error_action)) === null || _m === void 0 ? void 0 : _m[0] : undefined
                },
                identities: {
                    attachedIdentities: formattedIdentities.filter(identity => identity.isSelected),
                    defaultIdentity: !defaultIdentityID ? {} : {
                        label: defaultIdentityName,
                        value: defaultIdentityID
                    }
                },
                tags: {
                    editorTags: [...existingTags]
                }
            };
            if (isMounted.current) {
                setStepInfo(proxiesCreateDefaultStepInfo);
            }
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            fetchDomainHandlerList();
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.proxyID) && parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10) !== 0) {
            const vars = {
                proxy_id: parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10)
            };
            let proxyAndTagsAndIdentities;
            proxiesHandler.fetchProxy(vars).then((proxy) => {
                var _a, _b;
                if (isMounted.current) {
                    if (((_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.id) && ((_b = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.status) === 'READY') {
                        proxyAndTagsAndIdentities = proxy;
                        setProxyTagsAndIdentities(proxy);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                fetchIdentitiesAndProviders(proxyAndTagsAndIdentities);
            });
        }
        else {
            fetchIdentitiesAndProviders(undefined);
        }
    }, []);
    if ((props.isEdit && proxyTagsAndIdentities === undefined) || !stepInfo) {
        return React.createElement(Skeleton, { count: 30 });
    }
    if (loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    return React.createElement(ContentWrapper, { testid: "proxy-create-edit-page" },
        React.createElement(ContentFormLayoutWrapper, { header: React.createElement(Header, { variant: "h1" }, props.isCreate || props.isClone ? HeaderStrings.create : HeaderStrings.edit) },
            React.createElement(ProxiesForm, { isCreate: props.isCreate, isEdit: props.isEdit, isClone: props.isClone, proxyAndTagsAndIdentities: proxyTagsAndIdentities, stepInfo: stepInfo, identities: listIdentities, domainACLs: domainACLs, providersList: providersList })));
};
export default ProxiesFormWrapper;
