var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ServiceInspections } from '@/presentation/handlers';
import { getOrgIDFromToken } from '@/lib/auth0/utils';
import { capitalizeFirstForcefully, isEmptyObject } from '@/lib/misc/utils';
import { UserRolesReadableText } from '@/presentation/pages/settings/settings-types';
import { uniqueAndSortedArrayOfObjectFilters } from '@/presentation/common-utils/common-utils';
export const SettingsOrganizationInitialValues = {
    allowedDomains: [],
    isWildCardPresent: false
};
export const GlobalServicesTypes = [
    'id',
    'searchableID',
    'provider',
    'service',
    'inspection',
    'status'
];
export class SettingsHandler {
    constructor(remotes) {
        this.settingsOrganization = SettingsOrganizationInitialValues;
        this.formatUsers = (listMemberships) => {
            var _a;
            const users = (_a = listMemberships === null || listMemberships === void 0 ? void 0 : listMemberships.Memberships) === null || _a === void 0 ? void 0 : _a.map(memberships => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                const { User, MembershipRoles } = memberships;
                const roles = MembershipRoles === null || MembershipRoles === void 0 ? void 0 : MembershipRoles.map(roleData => {
                    var _a, _b;
                    const role = {
                        membershipID: memberships === null || memberships === void 0 ? void 0 : memberships.id,
                        roleID: (_a = roleData === null || roleData === void 0 ? void 0 : roleData.Role) === null || _a === void 0 ? void 0 : _a.id,
                        roleName: (_b = roleData === null || roleData === void 0 ? void 0 : roleData.Role) === null || _b === void 0 ? void 0 : _b.role_name
                    };
                    return role;
                });
                const formattedUser = {
                    id: User === null || User === void 0 ? void 0 : User.id,
                    email: (_b = (_a = User === null || User === void 0 ? void 0 : User.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '',
                    given_name: (_c = User === null || User === void 0 ? void 0 : User.given_name) !== null && _c !== void 0 ? _c : '',
                    family_name: (_d = User === null || User === void 0 ? void 0 : User.family_name) !== null && _d !== void 0 ? _d : '',
                    fullname: ((_e = User === null || User === void 0 ? void 0 : User.given_name) !== null && _e !== void 0 ? _e : '') + ' ' + ((_f = User === null || User === void 0 ? void 0 : User.family_name) !== null && _f !== void 0 ? _f : ''),
                    created_at: User === null || User === void 0 ? void 0 : User.created_at,
                    verified: (User === null || User === void 0 ? void 0 : User.verified) ? 'ACTIVE' : 'INVITED',
                    roles: roles,
                    roleName: (_h = UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[(_g = roles === null || roles === void 0 ? void 0 : roles[0]) === null || _g === void 0 ? void 0 : _g.roleName]) !== null && _h !== void 0 ? _h : '',
                };
                return formattedUser;
            });
            return users;
        };
        this.formatGlobalServices = (globalServices, enabledServices, disabledServices, bypassServices) => {
            var _a;
            const formattedGlobalServices = [];
            (_a = globalServices === null || globalServices === void 0 ? void 0 : globalServices.GlobalServices) === null || _a === void 0 ? void 0 : _a.map(service => {
                var _a, _b, _c;
                let inspection = ServiceInspections.DISABLED;
                if (enabledServices.includes(service === null || service === void 0 ? void 0 : service.id)) {
                    inspection = ServiceInspections.ENABLED;
                }
                if (bypassServices.includes(service === null || service === void 0 ? void 0 : service.id)) {
                    inspection = ServiceInspections.BYPASS;
                }
                formattedGlobalServices.push({
                    id: service === null || service === void 0 ? void 0 : service.id,
                    service: service === null || service === void 0 ? void 0 : service.name,
                    title: (_a = service === null || service === void 0 ? void 0 : service.title) !== null && _a !== void 0 ? _a : '',
                    description: (_b = service === null || service === void 0 ? void 0 : service.description) !== null && _b !== void 0 ? _b : '',
                    provider: (_c = service === null || service === void 0 ? void 0 : service.Provider) === null || _c === void 0 ? void 0 : _c.name,
                    inspection: capitalizeFirstForcefully(inspection),
                    status: capitalizeFirstForcefully(inspection),
                    created_at: service === null || service === void 0 ? void 0 : service.created_at
                });
            });
            return formattedGlobalServices;
        };
        this.getPropertyFilteringOptions = (services) => {
            return services.GlobalServices.map((service) => {
                return {
                    propertyKey: 'service',
                    value: service.name
                };
            });
        };
        this.getOrgFuncEntityParams = (idOrg, func) => {
            return { org_id: parseInt(idOrg, 10), function: func };
        };
        this.getFormattedTokenGroupAllowedDomains = (options) => {
            return options.map((option) => {
                return {
                    label: option
                };
            });
        };
        this.remotes = remotes;
    }
    listAllUsers(orgID) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.users.listAllUsers(orgID);
        });
    }
    listRoles() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.roles.list();
        });
    }
    UpdateMembershipRole(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.memberships.update(params);
        });
    }
    listGlobalServices() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.services.listGlobal();
        });
    }
    listSelectedServices() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.services.list();
        });
    }
    listOrganizationPolicyFunctions() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.organization.listOrganizationPolicyFunctions();
        });
    }
    listGlobalPolicyFunctions() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            return (_b = (_a = this.remotes) === null || _a === void 0 ? void 0 : _a.globPolicy) === null || _b === void 0 ? void 0 : _b.list();
        });
    }
    sortServicesByName(services) {
        services.GlobalServices = services.GlobalServices.sort((a, b) => (a.name > b.name) ? 1 : -1);
        return services;
    }
    makeDomainsFromLabel(allowedDomainsArray) {
        return '{' + allowedDomainsArray.map(option => option.label).join(',') + '}';
    }
    createAndUpdateOrganizationPolicyFunction(idOrg, func) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.organization.createAndUpdateOrganizationPolicyFunctions(this.getOrgFuncEntityParams(idOrg, func));
        });
    }
    getOrgDetail(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.organization.getOrgDetail(params);
        });
    }
    updateOrganizationSettings(orgID, allowedDomains, enforceMFA) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                org_id: orgID,
                allowed_domains: this.makeDomainsFromLabel(allowedDomains),
                enforce_mfa: enforceMFA
            };
            return yield this.remotes.organization.updateOrganizationSettings(params);
        });
    }
    updateGlobalServices(globalServices, inspection) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield this.remotes.services.getToken();
            const orgID = getOrgIDFromToken(token);
            const objects = globalServices.map(gService => {
                return {
                    global_service_id: gService === null || gService === void 0 ? void 0 : gService.id,
                    organization_id: orgID,
                    inspection: String(inspection).toLowerCase()
                };
            });
            const vars = {
                objects: objects
            };
            return yield this.remotes.services.createServiceMapping(vars);
        });
    }
    addUsers(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.users.addUser(params);
        });
    }
    deleteUser(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.users.deleteUser(params);
        });
    }
    resendVerification(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.users.resendVerification(params);
        });
    }
    validatePolicyConfig(policy) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                policy: policy
            };
            return (_b = (_a = this.remotes) === null || _a === void 0 ? void 0 : _a.openPolicy) === null || _b === void 0 ? void 0 : _b.validate(params);
        });
    }
    listNotificationDestinations() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationDestinations.list();
        });
    }
    deleteNotificationDestination(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationDestinations.delete(params);
        });
    }
    createNotificationDestination(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.notificationDestinations.create(params);
        });
    }
    listAuditLogs(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.remotes.auditLogs.getURL(params).then((url) => __awaiter(this, void 0, void 0, function* () {
                return yield this.remotes.auditLogs.downloadLogs(url.presigned_url);
            }));
        });
    }
    getFormattedAuditLogs(logs) {
        var _a;
        const apiServiceFilterList = [];
        const userNameFilterList = [];
        const actionNameFilterList = [];
        const fomattedLogsData = (_a = logs === null || logs === void 0 ? void 0 : logs.Items) === null || _a === void 0 ? void 0 : _a.map((item) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
            apiServiceFilterList.push(item === null || item === void 0 ? void 0 : item.service);
            userNameFilterList.push((_b = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.name);
            actionNameFilterList.push((_d = (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.action) === null || _d === void 0 ? void 0 : _d.name);
            return Object.assign({ id: item.id, service: item === null || item === void 0 ? void 0 : item.service, timestamp: item === null || item === void 0 ? void 0 : item.timestamp, username: (_f = (_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.name, useremail: ((_j = (_h = (_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.email) === null || _j === void 0 ? void 0 : _j.toLowerCase()) || '', userrole: (_m = UserRolesReadableText === null || UserRolesReadableText === void 0 ? void 0 : UserRolesReadableText[(_l = (_k = item === null || item === void 0 ? void 0 : item.data) === null || _k === void 0 ? void 0 : _k.user) === null || _l === void 0 ? void 0 : _l.role]) !== null && _m !== void 0 ? _m : '', ipAddress: (_p = (_o = item === null || item === void 0 ? void 0 : item.data) === null || _o === void 0 ? void 0 : _o.http) === null || _p === void 0 ? void 0 : _p.ip, actiontype: (_r = (_q = item === null || item === void 0 ? void 0 : item.data) === null || _q === void 0 ? void 0 : _q.action) === null || _r === void 0 ? void 0 : _r.type, actionname: (_t = (_s = item === null || item === void 0 ? void 0 : item.data) === null || _s === void 0 ? void 0 : _s.action) === null || _t === void 0 ? void 0 : _t.name, rawJson: item }, (!isEmptyObject((_v = (_u = item === null || item === void 0 ? void 0 : item.data) === null || _u === void 0 ? void 0 : _u.action) === null || _v === void 0 ? void 0 : _v.parameters) && { parameters: item.data.action.parameters }));
        });
        const apiServiceFilteredList = uniqueAndSortedArrayOfObjectFilters(apiServiceFilterList, 'service');
        const userNameFilteredList = uniqueAndSortedArrayOfObjectFilters(userNameFilterList, 'username');
        const actionNameFilteredList = uniqueAndSortedArrayOfObjectFilters(actionNameFilterList, 'actionname');
        const formattedFilteredList = [...apiServiceFilteredList, ...userNameFilteredList, ...actionNameFilteredList];
        return { auditLogsData: fomattedLogsData, formattedFilteredList };
    }
}
