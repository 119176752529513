import React, { useEffect, useState } from 'react';
import { Box, Button, ColumnLayout, Container, Header, Link, SpaceBetween, StatusIndicator } from '@cloudscape-design/components-themed/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIdentitiesHandlers, useProxiesHandlers } from '@/presentation/providers';
import { IdentityConfigReadableText } from '@/presentation/handlers';
import Skeleton from 'react-loading-skeleton';
import { makeHealthCheck, makeProxyStatus, GetProxyProvidersList } from '@/presentation/pages/proxies/proxies-list-cards-config';
import { downloadBlob } from '@/lib/misc/utils';
import { getLocaleTimestamp, isOnline } from '@/presentation/common-utils/common-utils';
import { formatNumberToKMB, makePropertyFilterI18nStrings, makeNotification } from '@/presentation/pages';
import ContentWrapper from '@/presentation/components/content-wrapper';
import { useNotifications } from '@/presentation/providers/notifications-provider';
import { PATHS, TABLE_FILTERS_SESSION_STORAGE, TABLE_SORTING_SESSION_STORAGE } from '@/presentation/common-utils/constants';
import DeleteModal from '@/presentation/components/modal/delete-modal';
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
import { PROXY_MODE_TYPE_LABEL, PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING, ProxyDeleteShowProp, ProxyDeleteStrings } from '@/presentation/pages/proxies/proxies-types';
import { TableHeader } from '@/presentation/components/commons/common-components';
import { PREFERENCES_STORAGE_KEY, COLUMN_WIDTH_STORAGE_KEY } from '@/presentation/pages/constants-and-types';
import InmemoryDetailsTable from '@/presentation/components/table/inmemory-details-table';
import { ProxiesIdentitiesColumnDefinitions, ProxiesIdentitiesDetailsTablePreferences, ProxiesIdentitiesDetailsTableVisibleContentOptions, ProxiesIdentitiesDetailsTablePageSizeOptions, ProxiesIdentitiesDetailsFilteringProperties } from '@/presentation/pages/proxies/proxies-identities-config';
import ShowTags from '@/presentation/components/tags/show-tags';
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import { IdentitiesListTablePropertyFilterFilteringOptions } from './proxies-identities-config';
import { useIsMounted } from '@/presentation/hooks';
import { useErrorBoundaryContext } from "@/presentation/providers/error-boundary-provider";
import ExternalLink from '@/presentation/components/links/external-link';
import { SETTINGS_TABS } from '@/presentation/pages/settings/settings-types';
import DownloadCredentials from './proxies-download';
import ContentLayoutWrapper from '@/presentation/components/content-wrapper/content-layout-wrapper';
import { getIdentifierLabel } from '../identities/constants';
const indentifierLabelObject = getIdentifierLabel();
const ProxiesDetails = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const isProxyOnline = isOnline((_b = (_a = props.proxy) === null || _a === void 0 ? void 0 : _a.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.last_healthcheck_time);
    const humanizedHealthCheckTime = !((_d = (_c = props.proxy) === null || _c === void 0 ? void 0 : _c.Proxies_by_pk) === null || _d === void 0 ? void 0 : _d.last_healthcheck_time) ? '-' : getLocaleTimestamp((_f = (_e = props.proxy) === null || _e === void 0 ? void 0 : _e.Proxies_by_pk) === null || _f === void 0 ? void 0 : _f.last_healthcheck_time);
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Proxy Details") },
            React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "ID"),
                        React.createElement(Box, null, (_h = (_g = props === null || props === void 0 ? void 0 : props.proxy) === null || _g === void 0 ? void 0 : _g.Proxies_by_pk) === null || _h === void 0 ? void 0 : _h.id)),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Description"),
                        React.createElement(Box, null, (_k = (_j = props === null || props === void 0 ? void 0 : props.proxy) === null || _j === void 0 ? void 0 : _j.Proxies_by_pk) === null || _k === void 0 ? void 0 : _k.description))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Providers"),
                        React.createElement(Box, null, GetProxyProvidersList(((_m = (_l = props === null || props === void 0 ? void 0 : props.proxy) === null || _l === void 0 ? void 0 : _l.Proxies_by_pk) === null || _m === void 0 ? void 0 : _m.ProxyProviders) || []))),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Status"),
                        React.createElement(ColumnLayout, { columns: 2 },
                            React.createElement("div", { className: 'status-badge-container' },
                                React.createElement(Box, null, makeProxyStatus((String((_p = (_o = props.proxy) === null || _o === void 0 ? void 0 : _o.Proxies_by_pk) === null || _p === void 0 ? void 0 : _p.status).toUpperCase()))))))),
                React.createElement(SpaceBetween, { size: "l" },
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Health Check"),
                        React.createElement(Box, null, makeHealthCheck(isProxyOnline))),
                    React.createElement(Box, null,
                        React.createElement(Box, { variant: "awsui-key-label" }, "Last Health Check"),
                        React.createElement(Box, null, humanizedHealthCheckTime))))));
};
const ProxiesSettings = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23;
    const proxyDefaultMode = (_f = (_a = props === null || props === void 0 ? void 0 : props.proxiesHandler) === null || _a === void 0 ? void 0 : _a.getLearningModeLabel((_e = (_d = (_c = (_b = props === null || props === void 0 ? void 0 : props.proxy) === null || _b === void 0 ? void 0 : _b.Proxies_by_pk) === null || _c === void 0 ? void 0 : _c.ProxySettings) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.default_mode)) !== null && _f !== void 0 ? _f : '';
    const isLearningMode = (proxyDefaultMode === PROXY_MODE_TYPE_LABEL.LEARNING || ((_k = (_j = (_h = (_g = props === null || props === void 0 ? void 0 : props.proxy) === null || _g === void 0 ? void 0 : _g.Proxies_by_pk) === null || _h === void 0 ? void 0 : _h.ProxySettings) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.learning_mode));
    const isRegoRaiseError = (_p = (_o = (_m = (_l = props === null || props === void 0 ? void 0 : props.proxy) === null || _l === void 0 ? void 0 : _l.Proxies_by_pk) === null || _m === void 0 ? void 0 : _m.ProxySettings) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.rego_raise_error;
    const onErrorAction = (_u = PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING === null || PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING === void 0 ? void 0 : PROXY_SETTINGS_ERROR_ACTION_VALUES_STRING[(_t = (_s = (_r = (_q = props === null || props === void 0 ? void 0 : props.proxy) === null || _q === void 0 ? void 0 : _q.Proxies_by_pk) === null || _r === void 0 ? void 0 : _r.ProxySettings) === null || _s === void 0 ? void 0 : _s[0]) === null || _t === void 0 ? void 0 : _t.on_error_action]) !== null && _u !== void 0 ? _u : '';
    let domainACLLink = '';
    if ((_z = (_y = (_x = (_w = (_v = props === null || props === void 0 ? void 0 : props.proxy) === null || _v === void 0 ? void 0 : _v.Proxies_by_pk) === null || _w === void 0 ? void 0 : _w.ProxyDomainAcls) === null || _x === void 0 ? void 0 : _x[0]) === null || _y === void 0 ? void 0 : _y.DomainAcl) === null || _z === void 0 ? void 0 : _z.id) {
        domainACLLink = React.createElement(ExternalLink, { to: `${PATHS.SETTINGS}/${SETTINGS_TABS.DOMAIN_ACLS}/${(_4 = (_3 = (_2 = (_1 = (_0 = props === null || props === void 0 ? void 0 : props.proxy) === null || _0 === void 0 ? void 0 : _0.Proxies_by_pk) === null || _1 === void 0 ? void 0 : _1.ProxyDomainAcls) === null || _2 === void 0 ? void 0 : _2[0]) === null || _3 === void 0 ? void 0 : _3.DomainAcl) === null || _4 === void 0 ? void 0 : _4.id}`, label: (_10 = (_9 = (_8 = (_7 = (_6 = (_5 = props === null || props === void 0 ? void 0 : props.proxy) === null || _5 === void 0 ? void 0 : _5.Proxies_by_pk) === null || _6 === void 0 ? void 0 : _6.ProxyDomainAcls) === null || _7 === void 0 ? void 0 : _7[0]) === null || _8 === void 0 ? void 0 : _8.DomainAcl) === null || _9 === void 0 ? void 0 : _9.name) !== null && _10 !== void 0 ? _10 : '' });
    }
    return (React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Proxy Settings") },
        React.createElement(ColumnLayout, { columns: 3, variant: "text-grid" },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Default Mode"),
                    React.createElement(Box, null, proxyDefaultMode)),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Learning Mode"),
                    React.createElement(Box, null, isLearningMode
                        ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                        : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled"))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Rego Raise Error"),
                    React.createElement(Box, null, isRegoRaiseError
                        ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                        : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled")))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Default Identity"),
                    React.createElement(Box, null, ((_15 = (_14 = (_13 = (_12 = (_11 = props === null || props === void 0 ? void 0 : props.proxy) === null || _11 === void 0 ? void 0 : _11.Proxies_by_pk) === null || _12 === void 0 ? void 0 : _12.ProxySettings) === null || _13 === void 0 ? void 0 : _13[0]) === null || _14 === void 0 ? void 0 : _14.Identity) === null || _15 === void 0 ? void 0 : _15.name) || '-')),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Log Level"),
                    React.createElement(Box, null, ((_19 = (_18 = (_17 = (_16 = props === null || props === void 0 ? void 0 : props.proxy) === null || _16 === void 0 ? void 0 : _16.Proxies_by_pk) === null || _17 === void 0 ? void 0 : _17.ProxySettings) === null || _18 === void 0 ? void 0 : _18[0]) === null || _19 === void 0 ? void 0 : _19.debug) ? 'Debug' : 'Info')),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "On Error Action"),
                    React.createElement(Box, null, onErrorAction))),
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Non-Provider Traffic"),
                    React.createElement(Box, null, ((_23 = (_22 = (_21 = (_20 = props === null || props === void 0 ? void 0 : props.proxy) === null || _20 === void 0 ? void 0 : _20.Proxies_by_pk) === null || _21 === void 0 ? void 0 : _21.ProxySettings) === null || _22 === void 0 ? void 0 : _22[0]) === null || _23 === void 0 ? void 0 : _23.allow_noncloud_traffic)
                        ? React.createElement(StatusIndicator, { type: "success" }, "Enabled")
                        : React.createElement(StatusIndicator, { type: "error", colorOverride: "grey" }, "Disabled"))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Domain ACL"),
                    React.createElement(Box, null, domainACLLink ? React.createElement("div", { className: 'kivera-external-link' }, domainACLLink) : '-'))))));
};
const ProxiesIdentities = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { identities } = useIdentitiesHandlers();
    const identitiesHandler = identities;
    const navigate = useNavigate();
    const location = useLocation();
    const identityDetails = (_d = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.proxy) === null || _a === void 0 ? void 0 : _a.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.ProxySettings) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.Identity;
    const defaultIdentity = identityDetails ? [Object.assign(Object.assign({}, identityDetails), { isDefaultIdentity: true, identifier: (identityDetails === null || identityDetails === void 0 ? void 0 : identityDetails.identity_type) || '-', identifierReadableName: (_e = indentifierLabelObject === null || indentifierLabelObject === void 0 ? void 0 : indentifierLabelObject[identityDetails === null || identityDetails === void 0 ? void 0 : identityDetails.identity_type]) !== null && _e !== void 0 ? _e : '' })] : [];
    // const defaultIdentity = identityDetails ? [{...identityDetails, isDefaultIdentity : true, identifier: identityDetails?.identity_type || '-'}] : []
    const attachedIdentities = (props === null || props === void 0 ? void 0 : props.proxyIdentities) || [];
    const mergeDefaultAndAttachedIdentities = [...attachedIdentities, ...defaultIdentity];
    const totalItemsLength = ((_h = (_g = (_f = props === null || props === void 0 ? void 0 : props.proxy) === null || _f === void 0 ? void 0 : _f.Identities_aggregate) === null || _g === void 0 ? void 0 : _g.aggregate) === null || _h === void 0 ? void 0 : _h.count) || mergeDefaultAndAttachedIdentities.length;
    const counter = mergeDefaultAndAttachedIdentities.length > 0 ? `(${mergeDefaultAndAttachedIdentities.length}/${totalItemsLength})` : `(0/${totalItemsLength})`;
    const buttonText = mergeDefaultAndAttachedIdentities.length > 0 ? 'Manage Identities' : 'Attach Identity';
    const identitiesWithConfig = (mergeDefaultAndAttachedIdentities === null || mergeDefaultAndAttachedIdentities === void 0 ? void 0 : mergeDefaultAndAttachedIdentities.map(item => {
        return Object.assign(Object.assign({}, item), { configDetails: identitiesHandler === null || identitiesHandler === void 0 ? void 0 : identitiesHandler.getIdentifierDetails(item) });
    })) || [];
    return (React.createElement(Container, null,
        React.createElement(ColumnLayout, { columns: 1, variant: "text-grid" },
            React.createElement(Box, null,
                React.createElement(InmemoryDetailsTable, { header: React.createElement(TableHeader, { title: "Identities", counter: counter, selectedItems: mergeDefaultAndAttachedIdentities, totalItems: mergeDefaultAndAttachedIdentities, description: 'Identities attached to this proxy', actionButtons: React.createElement(Button, { onClick: () => { var _a, _b; return navigate(`${PATHS.PROXIES}/${(_b = (_a = props === null || props === void 0 ? void 0 : props.proxy) === null || _a === void 0 ? void 0 : _a.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.id}/edit#identities`); } }, buttonText) }), resourceName: 'identities', resourceTitle: 'proxy', columnDefinitions: ProxiesIdentitiesColumnDefinitions(location === null || location === void 0 ? void 0 : location.pathname, true), items: identitiesWithConfig, filteringAriaLabel: 'Filter Identities', filteringPlaceholder: 'Filter Identities', columnWidthStorageKey: COLUMN_WIDTH_STORAGE_KEY.PROXIES_VIEW_IDENTITIES_LIST, preferencesStorageKey: PREFERENCES_STORAGE_KEY.PROXIES_VIEW_IDENTITIES_LIST, defaultPreferences: ProxiesIdentitiesDetailsTablePreferences, visibleContentOptions: ProxiesIdentitiesDetailsTableVisibleContentOptions, pageSizeOptions: ProxiesIdentitiesDetailsTablePageSizeOptions, ariaLabels: {}, emptyText: "No identities are attached to this proxy", propertyFilterI18nStrings: makePropertyFilterI18nStrings({ filteringPlaceholder: 'Filter Identities' }), propertyFilterFilteringProperties: ProxiesIdentitiesDetailsFilteringProperties, propertyFilterFilteringOptions: IdentitiesListTablePropertyFilterFilteringOptions, propertyFilterStorageName: TABLE_FILTERS_SESSION_STORAGE.PROXIES_VIEW_IDENTITIES_LIST, propertySortingStorageName: TABLE_SORTING_SESSION_STORAGE.PROXIES_VIEW_IDENTITIES_LIST })))));
};
const ProxiesTags = (props) => {
    var _a, _b, _c, _d, _e;
    const tags = (_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.proxy) === null || _a === void 0 ? void 0 : _a.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.tags) !== null && _c !== void 0 ? _c : [];
    const currentId = (_e = (_d = props === null || props === void 0 ? void 0 : props.proxy) === null || _d === void 0 ? void 0 : _d.Proxies_by_pk) === null || _e === void 0 ? void 0 : _e.id;
    return React.createElement(ShowTags, { tags: tags, id: currentId, resource: "proxy", basePath: PATHS.PROXIES });
};
const ProxiesMonitoring = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    return React.createElement(React.Fragment, null,
        React.createElement(Container, { header: React.createElement(Header, { variant: "h2" }, "Monitoring") },
            React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" },
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Total Requests"),
                    React.createElement(Link, { variant: "awsui-value-large", href: "#" }, formatNumberToKMB((_f = (_e = (_d = (_c = (_b = (_a = props.proxy) === null || _a === void 0 ? void 0 : _a.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.Counters_aggregate) === null || _c === void 0 ? void 0 : _c.aggregate) === null || _d === void 0 ? void 0 : _d.sum) === null || _e === void 0 ? void 0 : _e.counter_total_request) !== null && _f !== void 0 ? _f : 0))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Blocked Requests"),
                    React.createElement(Link, { variant: "awsui-value-large", href: "#" }, formatNumberToKMB((_m = (_l = (_k = (_j = (_h = (_g = props.proxy) === null || _g === void 0 ? void 0 : _g.Proxies_by_pk) === null || _h === void 0 ? void 0 : _h.Counters_aggregate) === null || _j === void 0 ? void 0 : _j.aggregate) === null || _k === void 0 ? void 0 : _k.sum) === null || _l === void 0 ? void 0 : _l.counter_denials) !== null && _m !== void 0 ? _m : 0))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Allowed Requests"),
                    React.createElement(Link, { variant: "awsui-value-large", href: "#" }, formatNumberToKMB((_t = (_s = (_r = (_q = (_p = (_o = props.proxy) === null || _o === void 0 ? void 0 : _o.Proxies_by_pk) === null || _p === void 0 ? void 0 : _p.Counters_aggregate) === null || _q === void 0 ? void 0 : _q.aggregate) === null || _r === void 0 ? void 0 : _r.sum) === null || _s === void 0 ? void 0 : _s.counter_accepts) !== null && _t !== void 0 ? _t : 0))),
                React.createElement(Box, null,
                    React.createElement(Box, { variant: "awsui-key-label" }, "Learn Mode Requests"),
                    React.createElement(Link, { variant: "awsui-value-large", href: "#" }, formatNumberToKMB((_z = (_y = (_x = (_w = (_v = (_u = props.proxy) === null || _u === void 0 ? void 0 : _u.Proxies_by_pk) === null || _v === void 0 ? void 0 : _v.Counters_aggregate) === null || _w === void 0 ? void 0 : _w.aggregate) === null || _x === void 0 ? void 0 : _x.sum) === null || _y === void 0 ? void 0 : _y.counter_notifies) !== null && _z !== void 0 ? _z : 0))))));
};
const ProxiesView = () => {
    var _a, _b, _c, _d;
    const { setErrorStatus } = useErrorBoundaryContext();
    const params = useParams();
    const navigate = useNavigate();
    const [proxyTagsAndIdentities, setProxyTagsAndIdentities] = useState(undefined);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const { proxies } = useProxiesHandlers();
    const proxiesHandler = proxies;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const onDeleteInit = () => setShowDeleteModal(true);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const onDownloadInit = () => setShowDownloadModal(true);
    const onDownloadDiscard = () => setShowDownloadModal(false);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const isMounted = useIsMounted();
    const makeSuccessNotification = (message) => {
        const msg = 'Proxy deleted successfully';
        return makeNotification('success', message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to create. Please try again later.';
        return makeNotification('error', message || msg, dismissNotification);
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onDeleteConfirm = () => {
        var _a;
        const toBeDeleted = parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10);
        (_a = proxiesHandler.deleteBulk([toBeDeleted])) === null || _a === void 0 ? void 0 : _a.then((result) => {
            // if success, unselect the items
            if (result === null || result === void 0 ? void 0 : result.success) {
                pushNotifications(makeSuccessNotification());
                navigate(PATHS.PROXIES);
            }
            else { // else show error notification
                pushNotifications(makeErrorNotification());
            }
        }).catch(({ response }) => {
            handleErrorResponse(response);
        }).finally(() => {
            // drop the modal and show success or error
            if (isMounted.current) {
                setShowDeleteModal(false);
            }
        });
    };
    useEffect(() => {
        if ((params === null || params === void 0 ? void 0 : params.proxyID) && parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10) !== 0) {
            const vars = {
                proxy_id: parseInt(params === null || params === void 0 ? void 0 : params.proxyID, 10)
            };
            if (isMounted.current) {
                setLoading(true);
            }
            proxiesHandler.fetchProxy(vars).then((proxy) => {
                var _a, _b;
                if (isMounted.current) {
                    if (proxy && ((_a = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.id) && ((_b = proxy === null || proxy === void 0 ? void 0 : proxy.Proxies_by_pk) === null || _b === void 0 ? void 0 : _b.status) === 'READY') {
                        const formattedItems = proxiesHandler === null || proxiesHandler === void 0 ? void 0 : proxiesHandler.getFormattedEntity(proxy);
                        setItems([formattedItems]);
                        setProxyTagsAndIdentities(proxy);
                    }
                    else {
                        setErrorStatus(true);
                    }
                }
            }).catch(console.error)
                .finally(() => {
                if (isMounted.current) {
                    setLoading(false);
                }
            });
        }
        else {
            setErrorStatus(true);
        }
    }, []);
    const onDownloadConfirm = () => {
        const proxyID = parseInt(params === null || params === void 0 ? void 0 : params.proxyID);
        if (isMounted.current) {
            setShowDownloadModal(false);
            setIsOverlayLoading(true);
        }
        proxiesHandler.getCredentials({ proxy_id: proxyID }).then((result) => {
            var _a;
            if (result.error) {
                const customMsg = "Unable to Download Credentials. Please try again later.";
                pushNotifications(makeErrorNotification((result === null || result === void 0 ? void 0 : result.msg) || customMsg));
            }
            else {
                const jsonBlob = new Blob([JSON.stringify(result.data, null, 4)]);
                const proxyFileNameWithID = 'proxy-credentials-' + ((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.client_id) + '.json';
                downloadBlob(jsonBlob, proxyFileNameWithID);
                pushNotifications(makeSuccessNotification('Proxy credentials downloaded successfully'));
            }
        }).catch(({ response }) => {
            const getResponseMessage = PagesRequestErrorHandler(response);
            pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
        }).finally(() => {
            if (isMounted.current) {
                setIsOverlayLoading(false);
            }
        });
    };
    if ((proxyTagsAndIdentities === undefined) || loading) {
        return React.createElement(Skeleton, { count: 30 });
    }
    const proxyIdentities = (_b = (_a = proxyTagsAndIdentities === null || proxyTagsAndIdentities === void 0 ? void 0 : proxyTagsAndIdentities.Proxies_by_pk) === null || _a === void 0 ? void 0 : _a.ProxyIdentities) === null || _b === void 0 ? void 0 : _b.filter(identity => !!identity.identity_id).map((identity) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return {
            id: identity === null || identity === void 0 ? void 0 : identity.identity_id,
            name: (_a = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _a === void 0 ? void 0 : _a.name,
            description: (_b = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _b === void 0 ? void 0 : _b.description,
            identifier: (_c = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _c === void 0 ? void 0 : _c.identity_type,
            identifierReadableName: (_e = IdentityConfigReadableText === null || IdentityConfigReadableText === void 0 ? void 0 : IdentityConfigReadableText[(_d = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _d === void 0 ? void 0 : _d.identity_type]) !== null && _e !== void 0 ? _e : '',
            dnsConfiguration: '',
            identity_type: (_f = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _f === void 0 ? void 0 : _f.identity_type,
            config: (_g = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _g === void 0 ? void 0 : _g.config,
            cloud_tenant: (_h = identity === null || identity === void 0 ? void 0 : identity.Identity) === null || _h === void 0 ? void 0 : _h.cloud_tenant,
        };
    });
    return (React.createElement(ContentWrapper, { testid: "proxy-create-page" },
        React.createElement(ContentLayoutWrapper, { header: React.createElement(Header, { variant: "h1", actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                    React.createElement(Button, { "data-testid": "click-to-download-this-proxy", variant: "normal", onClick: onDownloadInit }, "Download Credentials"),
                    React.createElement(Button, { "data-testid": "click-to-delete-this-proxy", onClick: onDeleteInit }, "Delete"),
                    React.createElement(Button, { variant: "primary", "ata-testid": "click-to-edit-this-proxy", onClick: () => {
                            navigate(PATHS.PROXIES + '/' + (params === null || params === void 0 ? void 0 : params.proxyID) + '/edit');
                        } }, "Edit")) }, (_d = (_c = proxyTagsAndIdentities === null || proxyTagsAndIdentities === void 0 ? void 0 : proxyTagsAndIdentities.Proxies_by_pk) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '') },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(ProxiesDetails, { proxy: proxyTagsAndIdentities, proxiesHandler: proxiesHandler }),
                React.createElement(ProxiesSettings, { proxy: proxyTagsAndIdentities, proxiesHandler: proxiesHandler }),
                React.createElement(ProxiesIdentities, { proxyIdentities: proxyIdentities, proxy: proxyTagsAndIdentities }),
                React.createElement(ProxiesTags, { proxy: proxyTagsAndIdentities }),
                React.createElement(ProxiesMonitoring, { proxy: proxyTagsAndIdentities }),
                React.createElement(DeleteModal, { visible: showDeleteModal, onDiscard: onDeleteDiscard, onDelete: onDeleteConfirm, items: items, deleteModalStrings: ProxyDeleteStrings, itemDeleteShowProp: ProxyDeleteShowProp }),
                isOverlayLoading && React.createElement(OverlayBouncyLoader, null),
                React.createElement(DownloadCredentials, { visible: showDownloadModal, onDiscard: onDownloadDiscard, onDownload: onDownloadConfirm })))));
};
export default ProxiesView;
