import { Button, Form, SpaceBetween } from "@cloudscape-design/components-themed/components";
import ProxiesDetails from "@/presentation/pages/proxies/proxies-details";
import React, { useCallback, useState } from "react";
import ProxiesIdentities from "@/presentation/pages/proxies/proxies-identities";
import { makeI18nStrings } from "@/presentation/components/I18nStrings";
import { useProxiesHandlers } from "@/presentation/providers";
import { useNavigate } from "react-router-dom";
import { PATHS, PATH_PROXIES } from "@/presentation/common-utils/constants";
import { useNotifications } from "@/presentation/providers/notifications-provider";
import { makeNotification } from "@/presentation/pages";
import { PagesRequestErrorHandler } from '@/presentation/pages/errors';
import FormTags from '@/presentation/components/tags/form-tags';
import { useIsMounted } from '@/presentation/hooks';
import { PROXY_SETTINGS_ERROR_ACTION_VALUES } from '@/presentation/pages/proxies/proxies-types';
import { makeInvalidFormErrorMessage } from '@/presentation/common-utils/common-utils';
const ProxiesForm = (props) => {
    var _a;
    const [stepsInfo, setStepsInfo] = useState(props.stepInfo);
    const { nameValidator, descriptionValidator, proxies } = useProxiesHandlers();
    const navigate = useNavigate();
    const proxiesHandler = proxies;
    const { pushNotifications, dismissNotification } = useNotifications();
    const [nameError, setNameError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [overrideNameErrorText, setOverrideNameErrorText] = useState("");
    const [tagsError, setTagsError] = useState(false);
    const isMounted = useIsMounted();
    const onStepInfoChange = useCallback((stateKey, newStepState) => {
        setStepsInfo(Object.assign(Object.assign({}, stepsInfo), { [stateKey]: Object.assign(Object.assign({}, stepsInfo[stateKey]), newStepState) }));
    }, [stepsInfo]);
    const makeSuccessNotification = (message) => {
        const msg = 'Proxy ' + ((props.isCreate || props.isClone) ? 'Created' : 'Updated') + ' Successfully';
        return makeNotification("success", message || msg, dismissNotification);
    };
    const makeErrorNotification = (message) => {
        const msg = 'Unable to ' + ((props.isCreate || props.isClone) ? 'create' : 'update') + '. Please try again later.';
        return makeNotification("error", message || msg, dismissNotification);
    };
    const onCancel = () => {
        if (props.isEdit) {
            navigate(PATHS.PROXIES + '/' + stepsInfo.details.id);
        }
        else {
            navigate(PATHS.PROXIES);
        }
    };
    const handleErrorResponse = (response) => {
        const getResponseMessage = PagesRequestErrorHandler(response);
        if ((getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorType) === "UniqueNameViolation" && isMounted.current) {
            setNameError(true);
            setOverrideNameErrorText("Duplicate proxy name. Please enter a unique proxy name.");
        }
        pushNotifications(makeErrorNotification(getResponseMessage === null || getResponseMessage === void 0 ? void 0 : getResponseMessage.errorMessage));
    };
    const onSubmit = () => {
        var _a, _b, _c, _d;
        const nValid = nameValidator === null || nameValidator === void 0 ? void 0 : nameValidator.validate(stepsInfo.details.name);
        const dValid = descriptionValidator === null || descriptionValidator === void 0 ? void 0 : descriptionValidator.validate(stepsInfo.details.description);
        if (!(nValid === null || nValid === void 0 ? void 0 : nValid.valid) || !(dValid === null || dValid === void 0 ? void 0 : dValid.valid) || tagsError) {
            if (isMounted.current) {
                setNameError(!(nValid === null || nValid === void 0 ? void 0 : nValid.valid));
                setDescError(!(dValid === null || dValid === void 0 ? void 0 : dValid.valid));
            }
            pushNotifications(makeErrorNotification(makeInvalidFormErrorMessage('proxy', (props.isCreate || props.isClone) ? 'create' : 'update')));
        }
        else {
            if (isMounted.current) {
                setNameError(false);
                setDescError(false);
            }
            const updatedAttachedIdentities = (_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.identities) === null || _a === void 0 ? void 0 : _a.attachedIdentities.filter(item => { var _a, _b; return item.id !== Number((_b = (_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.identities) === null || _a === void 0 ? void 0 : _a.defaultIdentity) === null || _b === void 0 ? void 0 : _b.value); });
            const updatedParams = Object.assign(Object.assign({}, stepsInfo), { details: Object.assign(Object.assign({}, stepsInfo.details), { errorAction: (_d = (_c = (_b = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.details) === null || _b === void 0 ? void 0 : _b.errorAction) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : PROXY_SETTINGS_ERROR_ACTION_VALUES.DEFAULT_ACTION }), identities: Object.assign(Object.assign({}, stepsInfo.identities), { attachedIdentities: updatedAttachedIdentities }) });
            if (props.isEdit) {
                // All good lets update
                proxiesHandler.updateProxy(props.proxyAndTagsAndIdentities, props.providersList, updatedParams).then(result => {
                    var _a, _b, _c;
                    if ((_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.update_Proxies) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id) {
                        pushNotifications(makeSuccessNotification());
                        navigate(PATH_PROXIES.VIEW + '/' + updatedParams.details.id);
                    }
                    else {
                        pushNotifications(makeErrorNotification());
                    }
                }).catch(({ response }) => {
                    handleErrorResponse(response);
                });
            }
            else {
                // All good lets create
                proxiesHandler.createProxy(updatedParams).then(result => {
                    var _a, _b, _c;
                    const createdId = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.insert_Proxies) === null || _a === void 0 ? void 0 : _a.returning) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.id;
                    if (createdId) {
                        const messsge = (React.createElement("span", null,
                            "Proxy #",
                            createdId,
                            " Created Successfully"));
                        pushNotifications(makeSuccessNotification(messsge));
                        navigate(PATHS.PROXIES);
                    }
                    else {
                        pushNotifications(makeErrorNotification());
                    }
                }).catch(({ response }) => {
                    handleErrorResponse(response);
                });
            }
        }
    };
    const i18nStrings = makeI18nStrings(props, "Proxy");
    const onTagsChange = (newtagsInfo) => {
        setTagsError(!(newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.valid));
        onStepInfoChange("tags", { editorTags: (newtagsInfo === null || newtagsInfo === void 0 ? void 0 : newtagsInfo.tags) || [] });
    };
    return (React.createElement("div", { className: 'form-container' },
        React.createElement(Form, { actions: React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" },
                React.createElement(Button, { variant: "link", onClick: onCancel }, i18nStrings.cancelButton),
                React.createElement(Button, { variant: "primary", onClick: onSubmit }, i18nStrings.submitButton)) },
            React.createElement(SpaceBetween, { size: "l" },
                React.createElement(ProxiesDetails, { info: stepsInfo, isEdit: props.isEdit, onChange: newStepState => onStepInfoChange('details', newStepState), setActiveStepIndex: () => { }, providersList: props.providersList, spacing: 'l', validation: {
                        nameError,
                        descError,
                        overrideNameErrorText
                    }, domainACLs: props.domainACLs }),
                React.createElement(ProxiesIdentities, { info: stepsInfo, onChange: newStepState => onStepInfoChange('identities', newStepState), setActiveStepIndex: () => { }, identities: props.identities, spacing: 'l' }),
                React.createElement(FormTags, { tags: ((_a = stepsInfo === null || stepsInfo === void 0 ? void 0 : stepsInfo.tags) === null || _a === void 0 ? void 0 : _a.editorTags) || [], onChange: (newStepState) => {
                        onTagsChange(newStepState);
                    } })))));
};
export default ProxiesForm;
