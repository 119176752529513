export var SETTINGS_TABS;
(function (SETTINGS_TABS) {
    SETTINGS_TABS["ORG_POLICY_FUNCTIONS"] = "org-policy-functions";
    SETTINGS_TABS["NOTIF_DESTINATIONS"] = "notification-destinations";
    SETTINGS_TABS["USER_ACCESS"] = "user-access";
    SETTINGS_TABS["AUDIT_LOGS"] = "audit-logs";
    SETTINGS_TABS["GLOBAL_SERVICES"] = "global-services";
    SETTINGS_TABS["SSO"] = "sso";
    SETTINGS_TABS["CLOUD_TENANTS"] = "cloud-tenant-structures";
    SETTINGS_TABS["DOMAIN_ACLS"] = "domain-acls";
    SETTINGS_TABS["BILLING"] = "billing";
})(SETTINGS_TABS || (SETTINGS_TABS = {}));
export var UserRolesReadableText;
(function (UserRolesReadableText) {
    UserRolesReadableText["kivera-admin"] = "Kivera Admin";
    UserRolesReadableText["kivera-user"] = "Kivera User";
    UserRolesReadableText["kivera-read-only"] = "Kivera Readonly";
})(UserRolesReadableText || (UserRolesReadableText = {}));
export var ROLETYPE;
(function (ROLETYPE) {
    ROLETYPE["READONLY"] = "kivera-read-only";
    ROLETYPE["USER"] = "kivera-user";
    ROLETYPE["ADMIN"] = "kivera-admin";
    ROLETYPE["SUPPORT"] = "support";
})(ROLETYPE || (ROLETYPE = {}));
export const SettingsWizardEditI18nStrings = {
    stepNumberLabel: stepNumber => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    navigationAriaLabel: "Steps",
    nextButton: 'Next',
    submitButton: 'Update Settings',
    optional: 'optional',
    skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
};
export const UserDeleteStrings = {
    headerTextSingular: 'Delete User',
    headerTextPlural: ' Delete Users',
    modalTextSingular: 'user',
    modalTextPlural: 'users',
    consentText: 'confirm'
};
export const UserDeleteShowProp = 'email';
