var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@/lib/auth0';
import { NavLink } from 'react-router-dom';
import gravatarUrl from 'gravatar-url';
import { PATHS } from '@/presentation/common-utils/constants';
import Skeleton from 'react-loading-skeleton';
import OverlayBouncyLoader from '@/presentation/components/loader/overlay-bouncy-loader';
import { SessionStorageAdapter } from '@/infra/cache';
import { useAppContext } from '../providers/app-context-provider';
import { getUrlParamsName, sortArrayOfObjectsByProperty } from '../common-utils/common-utils';
import { useFloating, useInteractions, autoUpdate, useDismiss, useRole, useClick, FloatingFocusManager, useId } from '@floating-ui/react';
import { useIsMounted } from '@/presentation/hooks';
import SwitchOrganizationMenu from '@/presentation/components/menu/switch-org-menu';
import UnsavedChangesModal from "@/presentation/components/modal/unsaved-changes-modal";
import { Tooltip } from 'react-tooltip';
const HeaderUserMenu = ({ setActivePath, position }) => {
    var _a, _b, _c, _d;
    const [memberships, setMemberships] = useState([]);
    const [userFullName, setUserFullName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [orgName, setOrgName] = useState('');
    const [activeOrgID, setActiveOrgID] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const isMounted = useIsMounted();
    const [showModal, setShowModal] = useState(false);
    const [requestedOrgId, setRequestedOrgId] = useState(0);
    const floatPlacement = position === 'sidebar' ? 'right-start' : 'bottom-end';
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: floatPlacement,
        whileElementsMounted: autoUpdate
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);
    const headingId = useId();
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    // State to track overflow status
    const [isOverflowing, setIsOverflowing] = useState(false);
    const { user, refreshToken, logout } = useAuth0();
    const { setIsSwitchOrg, isFormDirty, setIsFormDirty, setDirtyFormResourceName } = useAppContext();
    const refreshAuthToken = (params) => __awaiter(void 0, void 0, void 0, function* () { return yield refreshToken(params); });
    const updateOrg = (userMemberships, orgID) => {
        var _a, _b, _c, _d;
        const org = userMemberships.filter((membership) => (membership === null || membership === void 0 ? void 0 : membership.organization.id) === orgID);
        if (org.length === 1) {
            setOrgName((_b = (_a = org[0]) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.company_name);
            setActiveOrgID((_d = (_c = org[0]) === null || _c === void 0 ? void 0 : _c.organization) === null || _d === void 0 ? void 0 : _d.id);
        }
    };
    const getUserInfo = (userdetails) => {
        var _a;
        return !(userdetails === null || userdetails === void 0 ? void 0 : userdetails.given_name) && !(userdetails === null || userdetails === void 0 ? void 0 : userdetails.family_name)
            ? (_a = userdetails === null || userdetails === void 0 ? void 0 : userdetails.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()
            : (userdetails === null || userdetails === void 0 ? void 0 : userdetails.given_name) + ' ' + (userdetails === null || userdetails === void 0 ? void 0 : userdetails.family_name);
    };
    const sortMembershipsByOrgName = (membershipData) => {
        const propertyExtractor = (item) => { var _a, _b; return (_b = (_a = item === null || item === void 0 ? void 0 : item.organization) === null || _a === void 0 ? void 0 : _a.company_name) === null || _b === void 0 ? void 0 : _b.toLowerCase(); };
        return sortArrayOfObjectsByProperty(membershipData, propertyExtractor);
    };
    // Function to check overflow
    const checkOverflow = () => {
        const container = containerRef.current;
        const content = contentRef.current;
        if (container && content) {
            // Check horizontal overflow
            const isHorizontallyOverflowing = content.scrollWidth > container.clientWidth;
            // Update state with the overflow status
            setIsOverflowing(isHorizontallyOverflowing);
        }
    };
    useEffect(() => {
        // Check for overflow on mount
        if (isMounted.current) {
            checkOverflow();
        }
    }, [containerRef.current, contentRef.current]);
    useEffect(() => {
        var _a, _b;
        if (isMounted.current) {
            setLoading(true);
            const fullUserDetails = user;
            if (fullUserDetails) {
                const currentMemberShips = (fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.memberships) || [];
                const sortedMembershipData = sortMembershipsByOrgName(currentMemberShips);
                setMemberships(sortedMembershipData);
                setUserFullName(getUserInfo(fullUserDetails));
                setUserEmail((_b = (_a = fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '');
                updateOrg(fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.memberships, fullUserDetails === null || fullUserDetails === void 0 ? void 0 : fullUserDetails.org_id);
            }
            setLoading(false);
        }
    }, [user]);
    const switchOrg = (id) => {
        if (isMounted.current) {
            setIsSwitchOrg(true);
            setIsOverlayLoading(true);
        }
        const params = {
            org_id: id
        };
        refreshAuthToken(params)
            .then(() => {
            console.log('Token refreshed');
            const clearSession = new SessionStorageAdapter();
            clearSession.clear();
            if (isMounted.current) {
                setRequestedOrgId(0);
            }
        })
            .catch(console.error);
    };
    const handleOnConfirm = () => {
        setIsFormDirty(false);
        setDirtyFormResourceName(null);
        setShowModal(false);
        switchOrg(requestedOrgId);
    };
    const handleOnCancel = () => {
        setShowModal(false);
        setRequestedOrgId(0);
    };
    const hanldeSwitchOrg = (id) => {
        if (isFormDirty) {
            setIsOpen(false);
            setShowModal(true);
            setRequestedOrgId(id);
        }
        else {
            switchOrg(id);
        }
    };
    if (loading || !user) {
        return React.createElement(Skeleton, { count: 30 });
    }
    const referrer = getUrlParamsName('referrer');
    const supportReferrer = referrer || window.location.pathname;
    const supportPath = `${PATHS.SUPPORT}?referrer=${supportReferrer}`;
    const offsetLeftOrRight = floatPlacement === "bottom-end" ? { right: '3px' } : { left: '3px' };
    const menuClasses = "block border-l-4 border-[#efefef] px-4 py-1.5 text-base text-sm text-gray-700 hover:border-[#cf4dff] hover:bg-gray-50";
    return React.createElement(React.Fragment, null,
        React.createElement(UnsavedChangesModal
        // @ts-ignore
        , { 
            // @ts-ignore
            showDialog: showModal, confirm: handleOnConfirm, cancel: handleOnCancel }),
        React.createElement("button", Object.assign({ ref: refs.setReference }, getReferenceProps()),
            React.createElement("div", { className: 'flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-[#cf4dff]]focus:ring-offset-2 min-w-max' },
                React.createElement("img", { className: "h-10 w-10 rounded-full popover-avatar", src: gravatarUrl((_b = (_a = user === null || user === void 0 ? void 0 : user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '', {
                        size: 200,
                        default: 'retro'
                    }), alt: "" }))),
        isOpen && (React.createElement(FloatingFocusManager, { context: context, modal: false },
            React.createElement("div", Object.assign({ className: "Popover", ref: refs.setFloating, style: floatingStyles, "aria-labelledby": headingId }, getFloatingProps()),
                React.createElement("div", { className: "popover-menu", style: Object.assign(Object.assign({ position: 'fixed' }, offsetLeftOrRight), { zIndex: 9999 }) },
                    React.createElement("div", { className: "w-56 rounded-md bg-[#efefef] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pr-1", ref: containerRef },
                        React.createElement("div", { className: "p-2 text-sm font-bold text-gray-400", role: "none" }, "ACCOUNT"),
                        React.createElement("div", { className: "group relative flex rounded-md p-1" },
                            React.createElement("div", { className: "mt-1 flex h-11 w-11 flex-none items-center justify-center" },
                                React.createElement("img", { className: "h-9 w-9 rounded-full", src: gravatarUrl((_d = (_c = user === null || user === void 0 ? void 0 : user.email) === null || _c === void 0 ? void 0 : _c.toLowerCase()) !== null && _d !== void 0 ? _d : '', {
                                        size: 200,
                                        default: 'retro',
                                    }), alt: 'User Avatar' })),
                            React.createElement("div", { className: "truncate" },
                                React.createElement("p", { className: "text-gray-900 font-bold" },
                                    " ",
                                    userFullName),
                                React.createElement("p", { "data-tooltip-id": "user-email-tooltip", className: "text-gray-500 truncate", ref: contentRef },
                                    " ",
                                    userEmail),
                                React.createElement("p", { className: "text-gray-500" },
                                    " ",
                                    orgName),
                                isOverflowing && React.createElement(Tooltip, { id: "user-email-tooltip", content: userEmail, place: "bottom-start", opacity: 1 }))),
                        React.createElement(NavLink, { to: PATHS.MYPROFILE, role: "popover-menu-item", className: menuClasses, onClick: () => {
                                setActivePath(PATHS.MYPROFILE);
                                setIsOpen(false);
                            } }, "My Profile"),
                        React.createElement(NavLink, { to: supportPath, role: "popover-menu-item", className: menuClasses, onClick: () => {
                                setActivePath(PATHS.SUPPORT);
                                setIsOpen(false);
                            } }, "Support"),
                        React.createElement(NavLink, { to: '#', role: "popover-menu-item", className: menuClasses, onClick: logout }, "Sign out"),
                        React.createElement("div", { className: "p-2 pt-0", role: "none" },
                            React.createElement("div", { className: "border-t-2 border-[#bcbccd]/[.2] pt-2 text-sm font-bold text-gray-400", role: "none" }, "ORGANIZATION")),
                        React.createElement(NavLink, { to: PATHS.SETTINGS, role: "popover-menu-item", className: menuClasses, onClick: () => {
                                setActivePath(PATHS.SETTINGS);
                                setIsOpen(false);
                            } }, "Settings"),
                        (memberships === null || memberships === void 0 ? void 0 : memberships.length) > 1 &&
                            React.createElement(SwitchOrganizationMenu, { memberships: memberships, activeOrgID: activeOrgID, switchOrg: hanldeSwitchOrg, position: position })))))),
        isOverlayLoading && React.createElement(OverlayBouncyLoader, null));
};
export default HeaderUserMenu;
